import React from 'react';
import Header from '../elements/header';
import Footer from '../elements/footer';

const About: React.FC = () => {
  return (
    <>
      <div className='bg-[#12101D] min-h-screen text-white'>  
        <Header />
        <div className='bg-[#FFFFFF33] pt-[1px]'></div>
        <div className='flex mt-[33px] mx-[33px] pb-[33px]'>
          <img src='/assets/about.svg' alt='about icon' className='h-[64px] w-[64px] mr-8'/>
          <div className='border-l pl-8' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
            <h1 className='text-3xl font-bold mb-8'>About Us</h1>
            <div>Aquila is a forward-thinking enterprise specializing in enhancing global business communications, strategic properties, and infrastructure projects through cutting-edge connectivity and security solutions. Leveraging state-of-the-art technologies, including blockchain and secure networks, Aquila focuses on providing a hyper-efficient banking system, safeguarding operations, and integrating advanced vehicles like NAVIGO Elite security vehicles into a comprehensive sensor network. The company's innovative approach ensures seamless operations for its clients by combining secure, reliable, and scalable solutions tailored to meet the needs of diverse industries. Aquila also explores future-focused opportunities through strategic partnerships, data centers, and sophisticated administrative frameworks to solidify its place in the global market.</div>
            <div className='pt-4'>Aquila is at the forefront of developing global enterprise business communications, strategic properties and infrastructure, focusing on enhancing connectivity, digital and physical security and logistics across vast geographic areas. The work includes enterprise capital management for a robust communication infrastructure, which encompasses subsea cables and national-level internet projects to link large populations and countries. Aquila facilitates network interlinks, server centers, and cloud services, providing global technological support to sectors such as oilfield, energy, mining, and other major industries. With a multinational orientation, the company aims to connect the world, bringing to life extensive communications infrastructure integrated with advanced physical and cybersecurity measures and compatibility with World Wide Web 3.0.</div>
          </div>
        </div>
      </div>
      <div className='bg-[#12101D] border-t' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
        <Footer />
      </div>
    </>
  );
};

export default About;