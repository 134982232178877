import React from 'react';
import Header from '../elements/header';
import Footer from '../elements/footer';

const Resources: React.FC = () => {
  return (
    <>
      <div className='bg-[#12101D] min-h-screen text-white'>  
        <Header />
        <div className='bg-[#FFFFFF33] pt-[1px]'></div>
        <div className='flex mt-[33px] mx-[33px] pb-[33px]'>
          <img src='/assets/resources.svg' alt='resources icon' className='h-[64px] w-[64px] mr-8'/>
          <div className='border-l pl-8' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
            <h1 className='text-3xl font-bold mb-8'>Resources</h1>
            <div>Aquila Corporation offers a wealth of resources designed to support its clients and partners in achieving operational excellence and technological advancement. These resources include access to cutting-edge cloud infrastructure, blockchain technology frameworks, and advanced security systems that are tailored to meet the specific needs of global enterprises. Additionally, Aquila provides comprehensive support through its dedicated team of experts, offering consultancy services, technical assistance, and continuous system optimization. Clients can also tap into Aquila's extensive knowledge base, featuring industry insights, best practices, and tools that enable them to stay ahead of market trends.</div>
            <div className='mt-4'>With access to strategic data centers, scalable web applications, and secure communication networks, Aquila’s resource pool empowers businesses to enhance their performance, security, and scalability in today’s fast-paced digital economy.</div>
          </div>
        </div>
      </div>
      <div className='bg-[#12101D] border-t' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
        <Footer />
      </div>
    </>
  );
};

export default Resources;