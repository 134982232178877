import React from 'react';
import Header from '../elements/header';
import Footer from '../elements/footer';

const Solutions: React.FC = () => {
  return (
    <>
      <div className='bg-[#12101D] min-h-screen text-white'>  
        <Header />
        <div className='bg-[#FFFFFF33] pt-[1px]'></div>
        <div className='flex mt-[33px] mx-[33px] pb-[33px]'>
          <img src='/assets/solutions.svg' alt='solutions icon' className='h-[64px] w-[64px] mr-8'/>
          <div className='border-l pl-8' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
            <h1 className='text-3xl font-bold mb-8'>Aquila Solutions</h1>
            <p>Aquila Corporation leverages the most advanced technologies to provide innovative solutions tailored to global enterprise needs. With a strong focus on security, efficiency, and scalability, Aquila integrates the latest tools and systems to ensure business continuity and operational excellence. Below is an expanded summary of these key advancements:</p>
            <ul className='pl-4 list-disc'>
                <li className='py-2'><strong>Blockchain Technology:</strong> Aquila utilizes blockchain to create hyper-efficient, transparent, and secure banking systems, ensuring fast and secure transactions across its global network.</li>
                <li className='py-2'><strong>Cloud Computing & Scalability:</strong> By leveraging cloud technologies, Aquila ensures rapid scalability and secure hosting of web applications, including its React JS and TypeScript-based platforms, optimized for performance and seamless deployment.</li>
                <li className='py-2'><strong>NAVIGO Elite Security Vehicles:</strong> Equipped with advanced sensor technologies, these vehicles act as mobile data hubs, enhancing real-time monitoring and feeding critical data into operations centers to improve security and situational awareness.</li>
                <li className='py-2'><strong>Real-Time Data Collection & Monitoring:</strong> The integration of cutting-edge sensor technology across its infrastructure enables Aquila to provide real-time, actionable data to improve decision-making and operational security.</li>
                <li className='py-2'><strong>Future-Proof Ecosystem:</strong> Aquila’s strategic investments in emerging technologies and its commitment to staying ahead of industry trends create a resilient, adaptable environment for its clients, ensuring they remain competitive in a rapidly changing digital landscape.</li>
                <li className='py-2'><strong>High-end Property Acquisition and Management:</strong> Purchasing strategically located premier hotels and similar properties offers a multitude of business opportunities with the potential for assured success when underpinned by careful assessment, big data analysis, including an evaluation of population growth, demographics, and security measures.</li>
                <li className='py-2'><strong>High-Performance Development Tools:</strong> Through the use of TypeScript and modern development frameworks, Aquila ensures its applications are built for speed, security, and scalability, providing a solid foundation for enterprise operations.</li>
                <li className='py-2'><strong>Strategic Partnerships & Data Centers:</strong> Aquila invests in data centers and collaborates with key industry partners to enhance operational stability, security, and efficiency, fostering future growth and development.</li>
                <li className='py-2'><strong>Artificial Intelligence:</strong> Aquila is also at the forefront of investing in advanced artificial intelligence solutions, designed to enhance automation, data analysis, and decision-making capabilities for global enterprises.</li>
            </ul>
            <div>This integration of modern technologies ensures that Aquila’s clients receive world-class solutions, enabling them to thrive in today’s competitive, fast-paced business environment.</div>
          </div>
        </div>
      </div>
      <div className='bg-[#12101D] border-t' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
        <Footer />
      </div>
    </>
  );
};

export default Solutions;