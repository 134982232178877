import React, {useState} from 'react';
import Header from '../elements/header';
import Footer from '../elements/footer';
import Notification from '../elements/notification';

const Contact: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);

  const handleShowBanner = () => {
    setShowBanner(true); // Trigger the banner to show
  };

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const submitForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
    handleShowBanner();
  };

  return (
    <>
      <div className='bg-[#12101D] min-h-screen text-white'>  
        <Header />
        <div className='bg-[#FFFFFF33] pt-[1px]'></div>
        <div className='flex mt-[33px] mx-[33px] pb-[33px]'>
          <img src='/assets/contact.svg' alt='contact icon' className='h-[32px] w-[32px] mr-8'/>
          <div className='border-l pl-8' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
            <h1 className='text-3xl font-bold mb-8'>Contact Aquila</h1>
            <div>Contact us to learn more about our solutions for global enterprises and governments.</div>
            <div className='py-4 text-2xl'>Send us a Message</div>
            <div className='flex gap-4'>
              <div className='pt-2 w-full'>
                <div className='font-bold mb-1'>First Name</div>
                <input required value={firstName} onChange={e => setFirstName(e.target.value)} className='rounded-full px-4 py-2 bg-black border w-full' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}/>
              </div>
              <div className='pt-2 w-full'>
                <div className='font-bold mb-1'>Last Name</div>
                <input value={lastName} onChange={e => setLastName(e.target.value)} className='rounded-full px-4 py-2 bg-black border w-full' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}/>
              </div>
            </div>
            <div className='flex gap-4'>
              <div className='pt-2 w-full'>
                <div className='font-bold mb-1'>Email</div>
                <input value={email} onChange={e => setEmail(e.target.value)} className='rounded-full px-4 py-2 bg-black border w-full' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}/>
              </div>
              <div className='pt-2 w-full'>
                <div className='font-bold mb-1'>Phone</div>
                <input value={phone} onChange={e => setPhone(e.target.value)} className='rounded-full px-4 py-2 bg-black border w-full' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}/>
              </div>
            </div>
            <div>
              <div className='font-bold mt-4 mb-1'>Message</div>
              <textarea value={message} onChange={e => setMessage(e.target.value)} className='px-4 py-2 bg-black border w-full' style={{borderRadius: '20px', borderColor: 'rgba(255, 255, 255, 0.3)' }} />
            </div>
            
            <button onClick={submitForm} className="contact_button px-20 py-[8px] text-black mt-4">SEND MESSAGE</button>
          </div>
        </div>
      </div>
      <div className='bg-[#12101D] border-t' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
        <Footer />
      </div>
      <Notification 
        message="Thank You for your Message!" 
        showBanner={showBanner} 
        setShowBanner={setShowBanner} 
      />
    </>
  );
};

export default Contact;