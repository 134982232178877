import React from 'react';
import Header from '../elements/header';
import Footer from '../elements/footer';

const Partners: React.FC = () => {
  return (
    <>
      <div className='bg-[#12101D] min-h-screen text-white'>  
        <Header />
        <div className='bg-[#FFFFFF33] pt-[1px]'></div>
        <div className='flex mt-[33px] mx-[33px] pb-[33px]'>
          <img src='/assets/partners.svg' alt='partners icon' className='h-[64px] w-[64px] mr-8'/>
          <div className='border-l pl-8' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
            <h1 className='text-3xl font-bold mb-8'>Aquila Partners</h1>
            <div>Aquila Corporation strategically collaborates with a diverse range of industry-leading partners to enhance its global reach and technological capabilities. These partnerships enable Aquila to leverage cutting-edge innovations in cloud infrastructure, blockchain technology, cybersecurity, and real-time monitoring systems. By aligning with key players in data center operations, software development, and security solutions, Aquila ensures that its clients benefit from the most advanced and reliable systems available. These alliances not only strengthen Aquila’s operational framework but also provide invaluable resources for future growth, allowing the company to deliver integrated, high-performance solutions tailored to meet the evolving demands of businesses worldwide. Through these partnerships, Aquila remains at the forefront of industry trends, creating a robust ecosystem designed for long-term success.</div>
          </div>
        </div>
      </div>
      <div className='bg-[#12101D] border-t' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
        <Footer />
      </div>
    </>
  );
};

export default Partners;