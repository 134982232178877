import React from 'react';
import Header from '../elements/header';
import Footer from '../elements/footer';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
const Home: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <>
      <div className='home_background min-h-screen overflow-hidden'>
          <div className='relative z-10'>
            <Header />
          </div>   
          <div className='relative'>
            <img src="/assets/Union.svg" className='absolute left-[35%] top-[-200px] -z-9' alt='Background decoration'/>
          </div>
          <div className='bg-[#FFFFFF33] pt-[1px] b'></div>
          
          <div className="flex">
            <h1 className="text-4xl text-white mt-[15%] ml-[33px]">Leading Global Infrastructure</h1>
          </div>
          <div className='ml-[33px] pt-20 flex justify-between flex-wrap'>
            <div className='relative z-11'>
              <button className="contact_button px-20 py-[8px]" onClick={() => navigate('/contact')}>GET IN TOUCH</button>
            </div>
            <div className='w-full pt-4 pb-8 sm:pt-0 sm:w-1/3 text-white pr-[33px]'>
              Aquila delivers high-impact infrastructure projects globally, combining cutting-edge technology and sustainability to drive growth and improve lives, offering investors a pathway to meaningful, long-term returns.
            </div>        
          </div>
          <div className='bg-[#FFFFFF33] pt-[1px]'></div>
          <div className='flex text-white mt-[32px] flex-wrap sm:flex-nowrap mx-[33px]'>
            <div className='flex mb-4'>
              <img src='/assets/machine-learning.svg' alt='machine learning icon' className='h-[32px] w-[32px] mr-2'/>
              <div className='uppercase mr-4'>Access to emerging tech in security, energy, and digital infrastructure</div>
            </div>
            <div className='flex mb-4'>
              <img src='/assets/partnerships.svg' alt='partnerships icon' className='h-[32px] w-[32px] mr-2'/>
              <div className='uppercase mr-4'>Partnership opportunities in high-growth, sustainable projects worldwide.</div>
            </div>
            <div className='flex mb-4'>
              <img src='/assets/global.svg' alt='global icon' className='h-[32px] w-[32px] mr-2'/>
              <div className='uppercase mr-4'>Contribution to equitable development and prosperity across global communities.</div>
            </div>
          </div>
      </div>
      
      <div className='bg-[#12101D]'>
        <div className='bg-[#FFFFFF33] pt-[1px]'></div>  
        <div className='flex grid text-white mx-[33px] gap-8 mt-[33px] grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
          <div className="py-[32px] px-[24px] bg-cover bg-center server_bg">
            <div className='font-bold mb-[130px]'>Global Cloud Services and Infrastructure</div>
            <div>Offering scalable and secure cloud solutions, Aquila facilitates global connectivity and data management for businesses and governments, ensuring efficiency and reliability in digital operations.</div>
          </div>
          <div className="py-[32px] px-[24px] bg-cover bg-center security_bg ">
            <div className='font-bold mb-[130px]'>Security Technology and Cybersecurtiy</div>
            <div>Aquila provides advanced security technologies and cybersecurity measures, safeguarding critical infrastructure, data, and operations against evolving threats, ensuring safety and continuity.</div>
          </div>
          <div className="py-[32px] px-[24px] bg-cover bg-center oil_bg ">
            <div className='font-bold mb-[130px]'>Oil, Gas, and Mineral Development</div>
            <div>Specializing in sustainable extraction and development of natural resources, Aquila champions responsible practices in oil, gas, and mineral projects, balancing economic growth with environmental stewardship.</div>
          </div>
          <div className="py-[32px] px-[24px] bg-cover bg-center property_bg ">
            <div className='font-bold mb-[130px]'>Property Management</div>
            <div>Aquila's property management services focus on maximizing real estate value and sustainability, offering comprehensive solutions for property development, maintenance, and management across various sectors.</div>
          </div>
          <div className="py-[32px] px-[24px] bg-cover bg-center financial_bg ">
            <div className='font-bold mb-[130px]'>Financial and Capital Management</div>
            <div>With a strategic approach to finance and capital management, Aquila ensures the viability and growth of infrastructure projects through expert financial planning, investment, and risk management strategies.</div>
          </div>
        </div>
        <div className='bg-[#FFFFFF33] pt-[1px] mt-[33px]'></div> 
      </div>
       

      <div className='impact_background text-white px-[33px] py-[33px]'>
        <div className='flex gap-4 flex-wrap sm:flex-nowrap mt-28 mb-48'>
          <div className='flex mb-8 sm:border-r pr-4' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>
            <img src='/assets/pattern.svg' alt='pattern icon' className='h-[32px] w-[32px] mr-12'  />
            <div>
              <h2 className='text-3xl mb-6'>Innovation and Sustainability</h2>
              <div>At Aquila, we harness the latest in technology and innovation to ensure our projects not only meet current needs but are also adaptable for the future. From cybersecurity enhancements to cutting-edge energy solutions, we're committed to staying ahead of the curve.</div>
            </div>
          </div>
          <div className='flex mb-8 pr-4'>
            <img src='/assets/partnerships_divider.svg'alt='partnerships icon' className='h-[32px] w-[32px] mr-12'/>
            <div>
              <h2 className='text-3xl mb-6'>Partnerships and Impact</h2>
              <div>We build long-term relationships with governments, institutions, and businesses to create infrastructure solutions that have a lasting impact. By combining our expertise with the insights and resources of our partners, we deliver projects that drive economic growth and enhance community well-being.</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className='bg-[#12101D] border-t' style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}>

        <Footer />
      </div>
    </>
  );
};

export default Home;