import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Header: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [isMenuVisible, setIsMenuVisible] = useState(false); 

  return (
    <>
      <span className='hidden md:inline-block w-full'>
        <div className='py-[32px] px-[33px] flex justify-between flex-wrap z-1000'>
          <img src="/assets/logo.svg" alt="Logo" className='cursor-pointer' onClick={() => navigate('/')} />
          <div className="flex align-center sm:align-start text-white flex-wrap">
            <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/about')}>ABOUT</div>
            <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/partners')}>PARTNERS</div>
            <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/solutions')}>SOLUTIONS</div>
            <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/resources')}>RESOURCES</div>
          </div>
          <div>
            <button className="text-white py-[8px] px-[24px] rounded-full border border-white" onClick={() => navigate('/contact')}>Contact</button>
          </div>
        </div>
      </span>
      <div className='block md:hidden text-white'>
        <div className='pt-4 pl-[33px] pr-[24px] mb-4 flex justify-between'>
          <img src="/assets/logo.svg" alt="Logo" className='cursor-pointer' onClick={() => navigate('/')} />
          <div>
            {isMenuVisible === true ? 
              <img src='/assets/close_menu.svg' alt='close menu' className='h-[50px] cursor-pointer' onClick={() => setIsMenuVisible(false)} /> : 
              <img src='/assets/open_menu.svg' alt='open menu' className='h-[50px] cursor-pointer' onClick={() => setIsMenuVisible(true)} />
            }
          </div>
        </div>
        {isMenuVisible ? 
          <div className='grid place-content-center pb-4'>
            <div className='py-[12px] px-[24px] cursor-pointer text-center' onClick={() => navigate('/about')}>ABOUT</div>
            <div className='py-[12px] px-[24px] cursor-pointer text-center' onClick={() => navigate('/partners')}>PARTNERS</div>
            <div className='py-[12px] px-[24px] cursor-pointer text-center' onClick={() => navigate('/solutions')}>SOLUTIONS</div>
            <div className='py-[12px] px-[24px] cursor-pointer text-center' onClick={() => navigate('/resources')}>RESOURCES</div>
          </div>
          : null
        }
      </div>
    </>
  );
};

export default Header;