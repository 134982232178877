import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Footer: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  return (
    <div className='py-[32px] px-[33px] text-center pt-28'>
      <div className='grid place-content-center'>
        <img src="/assets/logo.svg" alt="Logo" className='cursor-pointer'  onClick={() => navigate('/')}/>
      </div>
      <div className='grid place-content-center mt-4'>
        <div className="flex align-center sm:align-start text-white flex-wrap">
          <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/about')}>ABOUT</div>
          <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/partners')}>PARTNERS</div>
          <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/solutions')}>SOLUTIONS</div>
          <div className='py-[12px] px-[24px] cursor-pointer' onClick={() => navigate('/resources')}>RESOURCES</div>
        </div>
      </div>
      <div className='text-[#FFFFFF33] mt-12'>&copy; 2024. All Rights Reserved. Aquila Enterprise is the US affiliate of Aquila Corporation in UK.</div>
    </div>
  );
};

export default Footer;