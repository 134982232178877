import React, { useState, useEffect } from "react";

interface NotificationProps {
  message: string;
  showBanner: boolean;
  setShowBanner: (value: boolean) => void; // Function to update the showBanner state
}

const Notification: React.FC<NotificationProps> = ({ message, showBanner, setShowBanner }) => {
  const [countdown, setCountdown] = useState(3); // Countdown starts at 10 seconds

  useEffect(() => {
    if (showBanner) {
      setCountdown(10); // Reset countdown when banner is shown

      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            setShowBanner(false); // Hide banner when countdown is done
            clearInterval(interval);
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [showBanner, setShowBanner]);

  return (
    <div
      className={`fixed bottom-0 w-full text-center bg-[#4794A6] text-white py-4 transform transition-transform ${
        showBanner ? "translate-y-0" : "translate-y-full"
      }`}
    >
      <p>
        {message}
      </p>
    </div>
  );
};

export default Notification;
